import React from 'react'
import DigitalLetter from '../images/icons/digital.svg'
import ArrowRight from '../images/icons/arrow-right.svg'
import '../css/digital.scss'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import Insight from './home/insight/insight'
import { Link } from 'gatsby'

const Digital = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="digital pt-5">

                <div className="position-relative">
                    {/* hero section */}
                    <div className="bg-digital py-5">
                        <div className="container py-3 my-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div className="d-md-block d-none" style={{ borderBottom: '3px solid #ffffff' }}></div>
                                        </div>
                                        <div className="col-md-8">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '2.8rem' }}>Digital</h1>

                                            <p className="text-white mb-0" style={{ lineHeight: '28px', fontSize: '1.3rem' }}>Digitize your business operations and be prepared to thrive in the digital market.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">
                                    <p className="small off-white mb-0" style={{ lineHeight: '26px', fontSize: '1.06rem' }}>Let us help your business go digital the right way! From your business models to your customer-facing touchpoints, we will work with you on digital strategies that will ensure you create new business values that will delight consumers.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="digital-letter-div mb-5">
                        <img src={DigitalLetter} alt="" className="digital-img" />
                    </div>
                </div>



                <div className="my-5 py-5"></div>
                <div className="my-md-5 py-md-5"></div>


                <div className="container my-5 py-5">
                    <div className="bg-draw py-5">
                        <div className="p-md-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h3 className="mb-4"> - Draw out a digital strategy</h3>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will pair your business with the perfect technology to transform your business models, internal capabilities, core operations and offerings, giving you the right leverage to compete and win in the digital economy.</p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Become a digital business.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Optimize business capabilities for improved performance.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Create competitive advantages with technology.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Future-proof your business.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Expand your business frontiers for new opportunities.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'digital' }}><button className="btn btn-red btn-small btn-red-black mr-4 text-uppercase px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/digital-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="bg-customer-journey py-5">
                        <div className="p-md-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h3 className="mb-4"> - Digitize the customer journey</h3>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will assess all your customer-facing processes and touchpoints, and find the right fit for digital technologies to foster better customer-business interactions, and create end-to-end processes that will leave amazing impressions in the minds of your customers and potential customers.</p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Convert all manual processes to digital processes.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Better understand your customers’ behaviour using data.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Create the perfect digital customer experience.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Attract new customers with streamlined digital processes.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Delight and retain customers with quality services.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Improve your customer turnaround time.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'digital' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/digital-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-5 py-5">
                    <div className="bg-digital-workforce py-5">
                        <div className="p-md-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h3 className="mb-4"> - Build a digital workforce</h3>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will infuse technology into the everyday life of your business, from core system operations down to organizational processes, empowering your business and workforce to take an agile approach in all your dealings and thrive in the digital landscape. </p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Create better collaboration suites. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Build a digital-first mindset across operations. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Make better and faster decisions using data. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Maintain a competitive workforce with tools that will meet the needs of your digital workplace. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Integrate your systems to meet changing business and market requirements. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Stay on top of fast and frequently changing digital technology to solve problems uniquely.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                <Link to={`/contact`} state={{path: 'digital'}}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                <Link className="my-auto" to="/digital-usecase"><p className="small cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="my-md-5 my-0 py-md-5 py-0"></div>
            <Insight />
        </Layout>
    )
}

export default Digital
